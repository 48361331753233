export default {
  "about": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre mission"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to deliver the safest treatment<br />for spinal fractures"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre vision"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A travers tout ce que nous faisons, nous pensons que nous pouvons améliorer la vie des patients et les flux de travail hospitaliers en fournissant les solutions prêtes à l’emploi les plus sûres, en particulier pour les fractures vertébrales."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre modèle"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modèle de Safe Orthopaedics est conçu pour examiner tous les aspects de l’environnement de soins de santé actuel."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre technologie innovante, conçue pour améliorer la chirurgie et optimiser les soins aux patients, aide l’hôpital à améliorer ses processus internes et externes et relever les défis économiques d’aujourd’hui."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cela se traduit par un partenariat de soins basé sur la valeur entre Safe Orthopaedics et ses partenaires."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’équipe"])}
  },
  "blogHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières actualités"])},
  "cage": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Insertion facilitée</strong><br />L’extrémité en ogive des cages permet de les insérer de manière simple et en douceur."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Rotation simplifiée</strong><br />Notre technologie de rail circulaire permet de guider la cage Cedar dans sa position finale."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Matériel éprouvé</strong><br />Le PEEK est un matériau éprouvé qui présente une bonne biocompatibilité et une élasticité proche de l’os, favorisant le processus naturel de fusion."])}
  },
  "calendar": {
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Janvier"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Février"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avril"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mai"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juin"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juillet"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Août"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septembre"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octobre"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décembre"])}
  },
  "career": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Rejoindre Safe Orthopaedics</strong>, c’est avant tout intégrer une <strong>équipe passionnée, portée par l’innovation</strong>, et désireuse d’apporter le traitement le plus sûr aux pathologies de fractures vertébrales, quel que soit le lieu ou le moment."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acteurs et ambassadeurs de cette mission, les collaborateurs de Safe Orthopaedics suivent un <strong>parcours d’intégration</strong>, leur permettant de comprendre leur place et l’importance que chacun occupe au sein de la société, et avec ses clients et partenaires."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoindre Safe Orthopaedics, c’est rejoindre une <strong>entreprise à taille humaine</strong>, bien implantée sur le territoire local, et aux multiples accents européens, avec des collaborateurs français, anglais et allemands."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Ce sont nos collaborateurs qui en parlent le mieux !</strong> Découvrez les !"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N’hésitez pas à nous envoyer votre candidature spontanée et rejoignez l’expérience Safe Orthopaedics !"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre d'emploi*"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message*"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données personnelles entrées dans ce formulaire sont traitées par Safe Orthopaedics afin de donner la possibilité à Safe Orthopaedics de donner suite à votre candidature et de mettre en place des entretiens de recrutement. Vous disposez d’un droit d’accès, de rectification, de limitation et de suppression de ces données personnelles. Pour plus d'informations, "])},
      "rgpdLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliquez ici"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champs est requis."])},
      "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), " caractères maximum autorisés."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'email non valide."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre message. Il a été envoyé."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite, veuillez renseigner les chamsp obligatoires."])}
    }
  },
  "cc": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine<sup>TM</sup> CC est notre instrumentation prête à l’emploi<br />pour notre cage cervicale : Walnut"])}
  },
  "cement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kit Ciment"])},
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciment pré-chargé dans le système de mélange"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haute viscosité"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de temps d’attente"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de risque d’irritation de l’appareil<br/>respiratoire et des yeux"])},
    "bullet5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système clos: non-toxique"])},
    "bullet6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion Luer"])}
  },
  "connector": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un unique kit pour une solution complète"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement et serrage des écrous<br />par le haut de la plaque"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaques “low-profile” : épaisseur 2 mm"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplicité et rapidité d’utilisation"])},
    "bullet5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
  },
  "contact": {
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet*"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message*"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données personnelles entrées dans ce formulaire sont traitées par Safe Orthopaedics afin de traiter vos demandes et d’y répondre. Vous disposez d’un droit d’accès, de rectification, de limitation et de suppression de ces données personnelles. Pour plus d'information, "])},
      "rgpdLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cliquez ici"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champs est requis."])},
      "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), " caractères maximum autorisés."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'email non valide."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci pour votre message. Il a été envoyé."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite, veuillez renseigner les champs obligatoires."])}
    }
  },
  "cypress": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Liberté chirurgicale totale</strong><br/>Nos vis Cypress sont canulées, fenestrées et pré-assemblées sur les prolongateurs de vis ainsi que sur le tournevis. Cela permet une totale liberté chirurgicale quant à l’utilisation du système en termes de pathologies, d’approche et d’augmentation de vis, avec la même instrumentation, les mêmes prolongateurs et les mêmes tournevis. Il n’y a pas de mauvaise insertion possible du bouchon lors du verrouillage des tiges."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Persuasion XXL</strong><br />Notre système permet une réduction totale de 38mm."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Visibilité inégalée</strong><br />Notre instrumentation radio-transparente permet une visibilité inégalée de la tête de vis, de la tige et du bouchon."])}
  },
  "education": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAFE ACADEMY"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement dispensé par des chirurgiens experts internationaux sur le thème de la fracture vertébrale."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venez découvrir notre offre complète et échanger avec vos confrères via une session didactique suivi d’une session pratique sur sujet anatomique."])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter le calendrier"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEER to PEER"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme de formation proposé au sein d’un de nos centres de référence européen pour assister à une ou des interventions chirurgicales."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session ouverte à un chirurgien dans un environnement one-to-one afin de partager les meilleures pratiques opératoires relatives à nos technologies."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VISITE D’ENTREPRISE"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formations pour les équipes hospitalières au sein de nos locaux. Visite guidée de l’entreprise, présentation du processus de fabrication des instruments et implants ainsi qu’ateliers pratiques sont au programme."])}
  },
  "home": {
    "banner_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez <a href='https://www.safegrp.com'>www.safegrp.com</a> pour consulter les publications liées à la bourse"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimisation"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flux hospitalier optimisé<br />Gain de temps global<br />Réduction des coûts"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN SAVOIR PLUS"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de contamination croisée<br />Moins de risque d’infection<br />Visibilité parfaite (Instruments radio transparents)"])},
    "numberTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["d’économie potentielle*"])},
    "numberText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’utilisation de nos produits peut permettre d’économiser jusqu’à 50% des coûts par cas dans les chirurgies à vis augmentée au ciment."])},
    "numberTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réduction du temps*"])},
    "numberText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’utilisation de nos produits fait économiser 80% du temps logistiques : manipulation pré- et post-opératoire réduite aux systèmes réutilisables."])},
    "numberTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kits chirurgicaux vendus*"])},
    "numberText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 20.000 kits chirurgicaux ont déjà aidé des chirurgiens et patients dans le monde entier.."])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data disponible"])}
  },
  "kypho": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système Cyphoplastie"])},
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Restauration de la hauteur en douceur</strong><br />Notre ballon 700 PSI de haute qualité permet une restauration de hauteur en douceur, même dans un os dur."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Consolidation osseuse uniforme</strong><br />Notre ballon permet une consolidation osseuse uniforme en créant une cavité de haute densité dans le corps vertébral. Celle-ci peut aider à augmenter la stabilité du corps vertébral et limiter les fuites de ciment en combinaison avec notre ciment osseux de haute viscosité."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Efficacité compacte</strong><br />Le système VA offre tous les instruments dont vous avez besoin dans une seule boite."])}
  },
  "lc": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine<sup>TM</sup> LC is our ready to use instrumentation<br />for our lumbar cage portfolio: Cedar & Elm"])}
  },
  "legal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENTIONS LEGALES"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le site <a href='https://www.safeorthopaedics.com'>www.safeorthopaedics.com</a> est édité par la société SAFE ORTHOPAEDICS, société anonyme au capital de 487.837,93 €, enregistrée au RCS de Pontoise sous le numéro 520 722 646 00020 et dont le siège social est situé Allée Rosa Luxemburg, Parc des Bellevues, Bâtiment le Californie, 95610 ERAGNY SUR OISE, n° de TVA intracommunautaire FR 22 520 722 646."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel.: +33 (0)4 81 09 87 01<br />Fax.: +33 (0)1 34 21 12 00"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarée à l’Agence National de sécurité du médicament et des produits de santé, 143-147 Boulevard Anatole France, 93285 Saint-Denis Cedex, en tant que fabricant de dispositifs médicaux conformément à l’article L5211-3-1 du Code de la santé publique."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Directeur de la publication :</strong> M Pierre Dumouchel, Directeur général"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Co-directeur de la publication :</strong> M. Sven Claes, Directeur Marketing"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Hébergeur :</strong> OVH, SAS enregistrée au RCS de Lille Métropole sous le numéro 424 761 419 00045, dont le siège social est situé 2 rue Kellermann, 59100 Roubaix, France"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Administrateur :</strong> SYNTHES’3D, SAS enregistrée au RCS d’Aix-en-Provence sous le numéro 495 075 822 00041, dont le siège social est situé 1 rue Mahatma Gandhi Le Decisium, Bâtiment C1, 13090 Aix-en-Provence"])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre société"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produits"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éducation"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine PS"])},
    "va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine VA"])},
    "lc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine LC"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine CC"])},
    "newsEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités & Évènements"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revue de Presse"])},
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
    "dataPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données personnelles"])}
  },
  "mission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre mission <span>safe</span>"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='focus'>Safe Orthopaedics</span> révolutionne les services proposés aux chirurgiens et hôpitaux traitant les patients souffrant du dos. Nos dispositifs médicaux prêts-à-l’emploi (implants steriles et instruments à usage-unique) sont associés à une technologie d’assistance virtuelle de chirurgie pour simplifier le flux opératoire, réduire les coûts d’opérations, réduire les risques chirurgicaux tout en préservant notre planète !"])},
    "one": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simplicité d’utilisation inédite<br />80% de la logistique chirurgicale annulée"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Nos technologies sont conçues pour être simple d’utilisation :</strong> design intuitifs, nombre optimisé d’instruments, prémontage des implants sur les instruments de pose, notice d’utilisation présente dans chaque kit ou accessible via SORA. "])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparées aux technologies traditionnelles qui nécessitent de multiples contrôles, nettoyages et stérilisation entre chaque chirurgie, nos kits sont neufs pour chaque patient, livrés stériles sous 24h au bloc opératoire quelques soient les conditions de prise en charge du patient : en urgence, en ambulatoire ou classique. Selon une étude réalisée par les hôpitaux publiques de Paris*, 80% de la logistique chirurgicale est annulée grâce à notre technologie."])}
    },
    "two": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50% d’économie potentielle sur le coût des implants"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La logistique simplifiée par nos technologies réduit de 18% le coût des implants. Selon une seconde étude réalisée sur les pathologies ostéoporotiques ou tumorales, cette économie est portée à 50%. "])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En digitalisant notre technologie comme le développement de nouvelles fonctionnalités SORA, nous apportons des solutions concrètes à la complexité historique des chirurgies orthopédiques et aux problématiques actuelles des hôpitaux. La gestion des stocks et du réassort de matériels, le coût de stérilisation significativement impacté par l’augmentation du prix de l’énergie, les exigences grandissantes de traçabilité…"])}
    },
    "three": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["66% de risques infectieux en moins"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En ne proposant que des implants stériles et des instruments à usage unique, nous annulons tous risques de contamination croisée et contribuons à réduire jusqu’à 66% le risque d’infection pendant la chirurgie. Selon nos estimations, un implant traditionnel, livré non stérile à l’hôpital, subit en moyenne 80 cycles de stérilisation autoclave d’être implanté. Le saviez-vous ? Quel est l’impact sur la performance finale de l’implant ? Peut-on garantir sa propreté, sa biocompatibilité ? Nous offrons une réponse simple et évidente : implant livré stérile, instrument à usage unique !"])}
    },
    "four": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins d’eau, Moins d’énergie, Moins de CO2"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concerné par les enjeux écologiques, Safe Group travaille l’empreinte écologique de ses technologies tout au long du cycle de vie.<br />Eco-conception : le design de nos instruments est pensé pour être le moins consommateur de matériau tout en restant simple et intuitif d’utilisation au bloc opératoire. Un de nos instruments multifonction en polymère renforcé de quelques grammes remplace plusieurs instruments traditionnels pouvant atteindre le demi-kilo."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eco-production : depuis l’acquisition de notre sous-traitant en 2020, nous centralisons la production de l’usinage jusqu’au conditionnement stérile. En comparaison à une sous-traitance multi-fournisseurs, nous avons réduit la consommation d’eau et d’énergie lié aux nombreux nettoyages mais aussi l’émission de C02 lié au transport. "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eco-distribution : en étroite collaboration avec nos clients, nous optimisons le matériel nécessaire et les transports associés à chaque chirurgie : les 40 kg de technologies traditionnelles sont remplacés par des kits prêt-à-l’emploi de 2 Kg et les Aller-Retours de matériels traditionnels vers les petits hôpitaux sont annulés. De part la simplicité de nos technologies, nous sommes capables en 2023 d’approvisionner les blocks opératoires au travers du monde en 48h."])}
    }
  },
  "modalPro": {
    "isPro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes vous un professionnel de santé ?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
  },
  "news": {
    "noPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a actuellement aucun post."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus"])},
    "pressReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revue de Presse"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualités"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évènements"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentations"])},
    "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Présentations financières"])},
    "pressRelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communiqués de Presse"])},
    "dl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger :"])},
    "shareholdersMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assemblées générales"])}
  },
  "oak": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Réduction de fracture sans concession</strong><br />La vis OAK est le seul système de réduction de fracture en MIS combinant les avantages des vis monoaxiales et polyaxiales grâce à une fonction d’auto-réduction, éliminant ainsi le dilemme de choisir entre une chirurgie Open avec des vis monoaxiales ou une chirurgie MIS avec des vis polyaxiales."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Visibilité inégalée</strong><br />Notre instrumentation radio-transparente permet une visibilité inégalée de la tête de vis, de la tige et du bouchon."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Force sans égale</strong><br />La vis Oak est au moins deux fois plus forte en compression qu’une vis polyaxiale standard, ce qui peut contribuer à la prévention de chirurgies de révision pour cause de vis défaillantes."])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DONNÉES PERSONNELLES"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données collectées sur le site internet sont traitées par <strong>Safe Orthopaedics</strong>, en tant que responsable de traitement au sens du Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La collecte des données est strictement limitée (principe de minimisation) et réalisée dans le cadre de mesures d’audience du site, ainsi que dans le cadre de la gestion des candidatures aux offres d’emploi via l’onglet « Carrière » et dans le cadre d’une demande de prise de contact via l’onglet « Contact »."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les données collectées visent soit à mesurer l’audience de ce site via des cookies, soit à créer un dossier de candidature à votre nom afin de nous donner la possibilité de donner suite à votre candidature et de mettre en place des entretiens de recrutement, soit afin de nous donner la possibilité de vous recontacter dans le cadre d’une demande de prise de contact afin de répondre à vos attentes."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’ensemble des données collectées est traité par <strong>Safe Orthopaedics</strong> et uniquement par les personnes de son organisation ayant à en connaître, et conservé pendant une durée maximale de 2 ans à compter de la collecte."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous disposez d’un droit d’accès aux données à caractère personnel vous concernant, ainsi qu’un droit de rectification, de limitation et de suppression de ces données conformément au Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 et à la Loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés, telle qu’amendée"])},
    "text6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Pour l’exercice de vos droits ainsi que pour toutes questions relatives à la protection de vos données à caractère personnel, merci d’adresser une notification à la société, à l’adresse suivante : <strong>Safe Orthopaedics</strong>, Allée Rosa Luxemburg, Parc des Bellevues, Bâtiment le Californie, 95610 ERAGNY SUR OISE ou ", _interpolate(_named("mail")), " ."])}
  },
  "product": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["découvrir"])}
  },
  "productRange": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme de produits"])}
  },
  "ps": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le kit original pour le traitement des fractures<br /> vertébrales et des pathologies dégénératives"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le kit essentiel pour le traitement<br />percutané des fractures vertébrales"])}
  },
  "slides": {
    "slide1": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leader of READY-TO-USE solutions"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for spinal diseases treatement"])}
    },
    "slide2": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["votre"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt"])}
    },
    "slide3": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos tubes les plus fins jamais conçus!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persuasion XXL"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité inégalée"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une chirurgie optimisée"])}
    },
    "slide4": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie<br />prête à l'emploi"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration du flux chirurgical<br />Gain de temps & de coûts<br />Sécuritée optimisée"])}
    },
    "slide5": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions pour le traitement<br />de la fracture vertébrale"])}
    },
    "slide6": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions pour les pathologies<br />dégénératives"])}
    }
  },
  "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défiler pour continuer"])},
  "sycamore": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Cyphoplastie à ancrage pédiculaire</strong><br />Restauration ciblée de la hauteur vertébrale<br />Fort ancrage pédiculaire<br />Stop ballon intégré efficace et rapide"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>120+ chirurgies 2022</strong>"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Suivi patient 12 mois</strong>"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Suivi multicentrique en cours en France et en Allemagne</strong>"])}
  },
  "teams": {
    "papillon": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicolas a 20 ans d’expérience dans le domaine des implants orthopédiques et du rachis. Après 3 années en tant que responsable export, il a fondé en fin 2005 la société Orthopaedic & Spine Development (OSD), sur Avignon. Président et principal actionnaire de la société, l’entreprise a mis sur le marché une gamme d’implants pour la chirurgie du rachis, vendues dans une quinzaine de pays. Nicolas est diplômé d’une école supérieure de commerce, 2003 – SKEMA, Sophia Antipolis."])}
    },
    "reynaud": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur Administratif & Financier"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["François-Henri a plus de 15 années d’expérience en financement d’entreprise. Il a d’abord contribué aux financements de projets d’entreprises innovantes au sein d’établissements financiers, puis a piloté plusieurs opérations de financement de haut de bilan (levées de fonds, fusions et acquisitions) en tant que Directeur Administratif et Financier de sociétés cotées et non cotées dans des secteurs tels que les dispositifs médicaux ou les énergies renouvelables."])}
    },
    "droulout": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directeur Technique et CoFondateur"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thomas a développé une expertise technique importante qu’il a acquise au cours de 15 années passées au sein de l’Industrie du Rachis. Avant de fonder Safe Orthopaedics en 2010, Thomas avait été impliqué dans de nombreux développements en tant que Chef de Projet Senior. Thomas détient un Master d’Ingénierie et de Matériaux Avancés et de Management obtenu aux Arts et Métiers et à l’ISTM."])}
    },
    "beyer": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Commercial Officer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikolaus Beyer a plus de 20 ans d’expérience dans l’industrie du rachis et a occupé divers postes de direction. Avant de rejoindre Safe Orthopaedics, Nik a occupé le poste de General Manager chez K2M pendant plus de 7 ans. Il a débuté sa carrière chez J&J dans la vente. Nik a rejoint Stryker en 1997 comme Responsable Ventes & Marketing et a occupé pendant plus de 7 ans le poste de General Manager/Managing Director GSA/Benelux and Nordics (11 pays) dans les domaines du rachis, craniomaxillofaciale, , biotech et navigation (solutions chirurgicales)."])}
    },
    "vazquez": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Directrice des Ressources Humaines"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nathalie évolue depuis 30 ans dans des contextes internationaux. Avant de rejoindre Safe Orthopaedics, elle a travaillé plus de 12 ans au sein de SpineVision où elle a débuté en qualité d’Office Manager. Elle a rapidement évolué sur le poste de Responsable Juridique et Administration Générale, notamment en charge de la gestion globale des Ressources Humaines pendant plus de 11 ans. Précédemment, Nathalie a tenu des postes d’Assistante de Direction auprès de hauts dirigeants de grands groupes internationaux."])}
    }
  },
  "va": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine<sup>TM</sup> est la plateforme de produits<br /> pour nos systèmes de cyphoplastie par ballonnets,<br/>et de ciment prêts à l’emploi."])}
  },
  "walnut": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>La stabilité profil zéro</strong><br />L’implant Walnut comprend deux vis entièrement intégrées dans la cage permettant son ancrage dans le corps vertébral."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Sécurité renforcée</strong><br />Les deux vis sont guidées de manière sécurisée dans le corps vertébral, ce qui assure une angulation et un ancrage optimal de la cage."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Matériau éprouvé</strong><br />Le PEEK est un matériau éprouvé qui présente une bonne biocompatibilité et une élasticité proche de l’os, favorisant le processus naturel de fusion."])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Β-TCP</strong><br />Un substitut osseux sur mesure pour une fusion optimale."])}
  }
}