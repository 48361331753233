<template>
    <header :class="type">
        <div class="header-container">      
            <div class="logo-container">
                <router-link :to="{name : 'Home'}">
                    <img src="./../assets/logo-safe-ortho.png" alt="logo" />
                </router-link>
            </div>
            <div class="menu-container">
                <nav>
                    <router-link :to="{name : 'Home'}" class="menu-item">{{ $t("menu.home")}}</router-link>
                    <router-link :to="{name : 'About'}" class="menu-item">{{ $t("menu.about")}}</router-link>
                    <router-link :to="{name : 'Products'}" class="menu-item">
                        {{ $t("menu.products")}}
                        <div class="sub-menu">
                            <router-link :to="{name : 'PS'}" class="menu-item-sub">{{ $t("menu.ps")}}</router-link>
                            <router-link :to="{name : 'VA'}" class="menu-item-sub">{{ $t("menu.va")}}</router-link>
                            <router-link :to="{name : 'LC'}" class="menu-item-sub">{{ $t("menu.lc")}}</router-link>
                            <router-link :to="{name : 'CC'}" class="menu-item-sub">{{ $t("menu.cc")}}</router-link>
                        </div>
                    </router-link>
                    <router-link :to="{name : 'News'}" class="menu-item">
                        {{ $t("menu.news")}}
                       <div class="sub-menu">
                            <router-link :to="{name : 'News'}" class="menu-item-sub">{{ $t("menu.newsEvents")}}</router-link>
                            <router-link :to="{name : 'Videos'}" class="menu-item-sub">{{ $t("menu.videos")}}</router-link>
                            <!-- <router-link :to="{name : 'Press'}" class="menu-item-sub">{{ $t("menu.press")}}</router-link> -->
                        </div>
                    </router-link>
                    <router-link :to="{name : 'Education'}" class="menu-item">{{ $t("menu.education")}}</router-link>
                    <router-link :to="{name : 'Career'}" class="menu-item">{{ $t("menu.career")}}</router-link>
                    <router-link :to="{name : 'Contact'}" class="menu-item">{{ $t("menu.contact")}}</router-link>
                </nav>
                <div class="lang-switcher">
                    <i class="fa-solid fa-globe switcher-icon"></i>   
                    <div class="switch-container">
                        <div class="lang-item" @click="$i18n.locale = 'en'">en</div>
                        <div class="lang-item" @click="$i18n.locale = 'fr'">fr</div>
                        <!-- <div class="lang-item" @click="$i18n.locale = 'de'">de</div> -->
                    </div>            
                </div>
            </div>
            <div class="menu-container-resp">
                <div class="switch-container-resp">
                    <div class="lang-item" @click="$i18n.locale = 'en'">en</div>
                    <div class="lang-item" @click="$i18n.locale = 'fr'">fr</div>
                    <!-- <div class="lang-item" @click="$i18n.locale = 'de'">de</div> -->
                </div>  
                <!-- MENU RESP -->
                <div class="menu-header-resp">
                    <div class="burger-nav" @click="$store.state.menu_open = !$store.state.menu_open" :class="$store.state.menu_open ? 'open' : ''" >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <nav class="menu-header-resp-content" v-if="$store.state.menu_open">
                        <router-link class="menu-item" :to="{name : 'Home'}" >{{ $t("menu.home")}}</router-link>
                        <router-link :to="{name : 'About'}" class="menu-item">{{ $t("menu.about")}}</router-link>
                        <div :class="['menu-item', { reverse : displayProducts }]" @click="displayProducts = !displayProducts">{{ $t("menu.products")}} <i class="icon-carret fa-solid fa-caret-down"></i></div>
                        <div class="sub-menu-resp" v-if="displayProducts">
                            <router-link :to="{name : 'PS'}" class="menu-item-sub">{{ $t("menu.ps")}}</router-link>
                            <router-link :to="{name : 'VA'}" class="menu-item-sub">{{ $t("menu.va")}}</router-link>
                            <router-link :to="{name : 'LC'}" class="menu-item-sub">{{ $t("menu.lc")}}</router-link>
                            <router-link :to="{name : 'CC'}" class="menu-item-sub">{{ $t("menu.cc")}}</router-link>
                        </div>
                        <div :class="['menu-item', {reverse : displayNews}]" @click="displayNews = !displayNews">{{ $t("menu.news")}} <i class="icon-carret fa-solid fa-caret-down"></i></div>                            
                        <div class="sub-menu-resp" v-if="displayNews">
                            <router-link :to="{name : 'News'}" class="menu-item-sub">{{ $t("menu.newsEvents")}}</router-link>
                            <router-link :to="{name : 'Videos'}" class="menu-item-sub">{{ $t("menu.videos")}}</router-link>
                            <!-- <router-link :to="{name : 'Press'}" class="menu-item-sub">{{ $t("menu.press")}}</router-link> -->
                        </div>
                        <router-link :to="{name : 'Education'}" class="menu-item">{{ $t("menu.education")}}</router-link>
                        <router-link :to="{name : 'Career'}" class="menu-item">{{ $t("menu.career")}}</router-link>
                        <router-link :to="{name : 'Contact'}" class="menu-item">{{ $t("menu.contact")}}</router-link>
                    </nav>
                </div>  
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name : "HeaderApp",
    data() {
        return { 
            locales: ["en", "fr"],
            // locales: ["en", "fr", "de"],
            displayNews : false,
            displayProducts : false,
            type : null
        };
    },
    watch : {
        '$route.name' : function() {
            this.displayNews = false;
            this.displayProducts = false;
            this.$store.commit("closeMenu");
        }
     },
    created () {
        window.addEventListener('scroll', this.handleScroll);
        this.getType();
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
         handleScroll() {
             this.getType();
        },
        getType() {
            if(window.pageYOffset < 100) {
                this.type = null;
            }else {
                this.type = "scroll";
            }
        },
    }
}
</script>

<style scoped lang="scss">
    header {
        display: flex;
        height:80px;
        position:fixed;
        top:0;
        left:0;
        right:0;
        justify-content: center;
        align-items: center;
        background:none;
        transition: 0.5s;
        z-index:9999999999;
    }

    header.scroll {
        background:$black;
    }

    .banner-container {
        height:35px;
        width:auto;
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 96%;
    }

    .logo-container img {
        height:35px;
        width:auto;
    }

    .menu-container {
        display: flex;
        align-items: center;
    }

    nav {
        margin-right:40px;
        display: flex;
    }

    .menu-item {
        padding:0 20px;
        color:#fff;
        text-decoration: none;
        font-size:15px;
        font-weight: 500;
        transition:0.5s;
        height:80px;
        position:relative;
        display: flex;
        align-items: center;
    }

    .menu-item:hover, .menu-item.router-link-exact-active {
        color:$mainColor;
    }

    .sub-menu {
        display: none;
        position: absolute;
        left: 0;
        top:80px;
        background:$black;
    }

    .menu-item:hover > .sub-menu {
        display: flex;
        flex-direction: column;
        width:180px;
    }

    .sub-menu .menu-item-sub {
        padding:10px 20px;
        color:#fff;
        text-decoration: none;
        font-size:15px;
        font-weight: 500;
        transition:0.5s;
    }

    .sub-menu .menu-item-sub:hover {
        background:$mainColor;
    }

    .switcher-icon {
        font-size:25px;
        color:#fff;
        display: flex;
        cursor: pointer;
        position:relative;
    }

    .switch-container {
        display: none;
        position: absolute;
        right: 2%;
        top:70px;
        background:$black;
    }

    .lang-switcher {
        height:80px;
        display: flex;
        align-items: center;
    }

    .lang-switcher:hover .switch-container {
        display: flex;
        flex-direction: column;
        background:none;
    }

    .lang-item {
        color:#fff;
        padding:8px 0;
        text-transform: uppercase;
        cursor: pointer;
        transition:0.5s;
    }

    .lang-item:hover {
        color:$mainColor;
    }

    .menu-container-resp {
        display: none;
    }

    /* MENU RESP */

    .burger-nav {
        width: 20px;
        height: 20px;
        position: relative;
        margin: 10px auto;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.5s ease-in-out;
        -moz-transition: 0.5s ease-in-out;
        -o-transition: 0.5s ease-in-out;
        transition: 0.5s ease-in-out;
        cursor: pointer;
    }

    .burger-nav span {
        display: block;
        position: absolute;
        height: 3px;
        width: 100%;
        background: #fff;
        border-radius: 4px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    .burger-nav span:nth-child(1) {
        top: 0px;
    }

    .burger-nav span:nth-child(2),
    .burger-nav span:nth-child(3) {
        top: 8px;
    }

    .burger-nav span:nth-child(4) {
        top: 16px;
    }

    .burger-nav.open span:nth-child(1) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .burger-nav.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .burger-nav.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .burger-nav.open span:nth-child(4) {
        top: 8px;
        width: 0%;
        left: 50%;
    }

    .menu-header-resp-content {
        position: fixed;
        top: 60px;
        left:0;
        right: 0;
        background: #000;
        border-bottom: 1px solid $mainColor;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-top:1px solid $mainColor;
        margin:0;
        z-index:99999;
    }

    .menu-header-resp-content .menu-item {
        width:100%;
        border: none;
        padding:10px 0;
        text-decoration: none;
        color:#fff;
        font-size:13px;
        display: flex;
        justify-content: center;
        align-items: center;
        height:auto;
        
    }

    .menu-header-resp-content .menu-item:hover, .menu-header-resp-content .menu-item.menu-item.router-link-exact-active {
        background:$mainColor;
    }

    .menu-header-resp-content .sub-menu {
        position:relative;
    }

    .sub-menu-resp {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-bottom:1px solid #fff;
        width:100%;
    }

    .sub-menu-resp .menu-item-sub {
        padding: 10px 0;
        color:#fff;
        display: flex;
        justify-content: center;
        font-size:13px;
        width:100%;
        text-decoration: none;
        transition:0.5s;
    }

    .sub-menu-resp .menu-item-sub:hover {
        color:$mainColor;
    }

    .icon-carret {
        margin-left: 5px;
        transition:0.5s;
    }

    .reverse .icon-carret {
        transform: rotate(-180deg);
    }


    @media screen and (max-width:1200px) {
        .menu-item {
            padding:0 10px;
        }

        .logo-container img {
            height : 30px;
        }

        nav {
            margin-right: 20px;
        }
    }

    @media screen and (max-width:900px) {
        .menu-container {
            display: none;
        }

        header {
            height:60px;
        }

        .menu-container-resp {
            display: flex;
            align-items:center
        }

        .menu-container-resp .switch-container-resp {
            display: flex;
            flex-direction: row;
            margin-right:10px;
        }

        .switch-container-resp .lang-item {
            padding:0 7px;
            font-size:13px;
        }

        .logo-container img {
            height : 25px;
        }
    }
</style>