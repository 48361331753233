import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/about-safe",
    name: "About",
    component: () => import("../views/About.vue")
  },
  {
    path: "/our-products",
    name: "Products",
    component: () => import("../views/Products.vue")
  },
  {
    path: "/our-products/sterispine-ps",
    name: "PS",
    component: () => import("../views/SteriSpine/PS.vue")
  },
  {
    path: "/our-products/sterispine-cc",
    name: "CC",
    component: () => import("../views/SteriSpine/CC.vue")
  },
  {
    path: "/our-products/sterispine-lc",
    name: "LC",
    component: () => import("../views/SteriSpine/LC.vue")
  },
  {
    path: "/our-products/sterispine-va",
    name: "VA",
    component: () => import("../views/SteriSpine/VA.vue")
  },
  {
    path: "/news-events/calendar-event",
    name: "Calendar",
    component: () => import("../views/Calendar.vue")
  },
  {
    path: "/news-events",
    name: "News",
    component: () => import("../views/News.vue")
  },
  {
    path: "/media",
    name: "Videos",
    component: () => import("../views/Videos.vue")
  },
  {
    path: "/press",
    name: "Press",
    component: () => import("../views/Press.vue")
  },
  {
    path: "/education",
    name: "Education",
    component: () => import("../views/Education.vue")
  },
  {
    path: "/career",
    name: "Career",
    component: () => import("../views/Career.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue")
  },
  {
    path: "/legal-notice",
    name: "LegalNotice",
    component: () => import("../views/LegalNotice.vue")
  },
  {
    path: "/data-privacy",
    name: "DataPrivacy",
    component: () => import("../views/DataPrivacy.vue")
  },
  {
    path: "/post/:post",
    name: "BlogItem",
    component: () => import("../views/BlogItem.vue")
  },
  {
    path: "/ifu-documentation",
    name: "ifu-documentation",
    component: () => import("../views/ifu-documentation.vue")
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {

      return { el: to.hash, behavior: 'smooth' }
    }
    return { top: 0 }
  }
})

export default router
