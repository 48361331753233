<template>
  <header-app></header-app>
  <router-view/>
  <footer-app></footer-app>
</template>

<script>
import HeaderApp from "./components/HeaderApp.vue";
import FooterApp from "./components/FooterApp.vue";

export default {
  name : "App",
  components : {
    HeaderApp,
    FooterApp
  },
  mounted() {
    //load curent lang
    if(localStorage.safeLang) {
      this.$i18n.locale = localStorage.safeLang;
    }    
  },
  watch: {
    '$i18n.locale' : function(newName) {
      localStorage.safeLang = newName;
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@font-face {
 font-family: "Forelle";
 src: local("Merienda"), url("./assets/fonts/Forelle/Forelle.ttf") format("truetype"),
}

body {
  padding:0;
  margin: 0;
  background-color: $black;
  font-family: "Comfortaa", cursive;
}

p {
  color:#fff;
  font-weight:300;
  line-height:1.8em;
  font-family: "Comfortaa", cursive;
  margin:0 0 20px 0;
  font-size:15px;
}

.title-special {
  font-family: "Forelle", cursive;
  font-weight:400;
}

.colored {
  color:$mainColor;
}

/* form */

input, textarea {
  font-family: "Comfortaa", cursive;
  color:#333;
  border-radius: 0;
  appearance: none;
  padding:10px;
  font-size:20px;
  background:#fff;
  border:2px solid #fff;
  font-weight: 300;
}

::placeholder {
    font-family: "Comfortaa", cursive;
  color:#333;
  font-weight: 300;
}

input:focus, textarea:focus {
  outline: none;
  border: 2px solid $mainColor;
}

.focus, .title-about span {
      color:$mainColor;
}

.form-group.error input {
    border-color:$red;
}

.error-msg {
    color:$red;
    font-size:15px;
    line-height:1.5em;
    margin-top:2px;
}

.notices {
    padding:10px;
    width:calc(100% - 20px);
    border-radius:10px;
    text-align:center;
    color:$mainColor;
    border:1px solid $mainColor;
    margin-top:20px;
    background: #fff;
    font-size:20px;
}

.notices.errors {
    color:$red;
    border-color:$red;
}

.checkbox-container {
  display:flex;
  align-items: center
}

input[type="checkbox"] {
    width:20px;
    min-width: 20px;
    height:20px;
    background:#fff;
    border:2px solid #fff;
    padding:0;
    margin-right:20px;
    cursor: pointer;
}

input[type="checkbox"]:checked {
    background:$mainColor;
}

.form-group.error input[type="checkbox"] {
    border:2px solid $red;
}

label {
  color:#fff;
  line-height: 1.3em;
  font-weight: 300;
}

button {
    border: none;
    outline:none;
    cursor: pointer;
}

.main {
  min-height: calc(100vh - 100px);
  padding-top:100px;
}

.row {
  display: flex;
  width:100%;
}

.row.reverse {
  flex-direction: row-reverse;
}

.col-20 {
  width:18%;
  margin:-20px 1%
}

.col-25 {
  width:23%;
  // width:2%;
  margin:0 1%
  // margin:50% 50%
}

.col-33 {
  width:31%;
  margin:0 1%
}

.col-40 {
  width:38%;
  margin:0 1%
}

.col-50 {
  width:48%;
  margin:-40px 1%;
}

.col-60 {
  width: 58%;
  margin:0 1%;
}

.col-25 {
  width: 23%;
  margin:-80px 1%
}

.col-25team {
  width: 23%;
  margin:0 1%
}

.col-75 {
  width: 73%;
  margin:0 1%
}

.box {
  width:$box;
  max-width: $max;
  margin:0 auto;
}

a {
  color:$mainColor;
  text-decoration: none;
  transition: 0.5s;
}

a:hover {
  color:#fff;
}

.section-full {
  min-height: 100vh;
  width:100%;
  display: flex;
  align-items: center;
}

.btn {
  text-decoration: none;
  color:#fff;
  background:$mainColor;
  padding:15px 25px;
  font-size:20px;
  display: inline-block;
  transition:0.5s;
  cursor: pointer;
}

.btn:hover {
  background: darken($mainColor, 10);
}

.btn:disabled {
    opacity: 0.5;
    cursor:not-allowed;
}

.btn.btn-second {
  background:#fff;
  color:#333;
}

.btn.btn-second:hover {
  background: darken(#fff, 20);
}

.btn-icon {
  font-size: 22px;
  margin-right:10px;
  top: 2px;
  position: relative;
}

/* modal */

.modal {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background:rgba(0,0,0,0.95);
  z-index: 99;
}

.modal-close {
  position:absolute;
  top:30px;
  right:30px;
  color:#fff;
  font-size:40px;
  cursor: pointer;
  display: block;
  width:35px;
  height:35px;
  background:url('./assets/close.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.modal-container {  
    max-width: 96%;
}

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("./assets/banner-products.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner .box {
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner-sep {
  margin:40px auto;
  width:300px;
  max-width: 60%;
  height:1px;
  background:$mainColor;
}

.banner-img {
  max-width: 90%;
  margin-top:80px;
}

.carousel__pagination-button {
    height:20px !important;
    width:20px !important;
    border:1px solid $mainColor !important;
    border-radius:50% !important;
    background:none !important;
}

.carousel__pagination-button--active {
  background:$mainColor !important;
}



@media screen and (max-width:768px) {
  .col-40, .col-60, .col-25, .col-75, .col-50, .col-33 {
    width:98%
  }

  .row, .row.reverse {
    flex-direction: column;
    width:100%;
  }

  .section-full {
    min-height: auto;
  }

  .modal-container {
    padding-top:100px;
  }

  input, textarea {
    font-size: 15px;
  }

  label {
    font-size:13px;
  }

  .carousel__track {
      transform: translateX(0) !important;
}


}
</style>
