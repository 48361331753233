import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import Vue3Autocounter from 'vue3-autocounter';

//font awesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas);
import { far } from '@fortawesome/free-regular-svg-icons';
library.add(far);
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fab);
import { dom } from "@fortawesome/fontawesome-svg-core";
dom.watch();

createApp(App).component("font-awesome-icon", FontAwesomeIcon).component('vue3-autocounter', Vue3Autocounter).use(i18n).use(store).use(router).mount("#app")
