export default {
  "about": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to deliver the safest treatment<br />for spinal fractures"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our vision"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything we do, we believe that we can improve patient lives and hospital workflows by delivering the safest ready to use solutions, especially for spinal fractures."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our model"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe Orthopedics’ model is designed to look at all aspects of today’s healthcare environment."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our innovative technology, designed to improve the surgery and optimize patient care helps the hospital to improve their internal and external processes and address the economic challenges of today."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This results in a value-based care partnership between Safe Orthopaedics and its partners."])},
    "title4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
  },
  "blogHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest News"])},
  "cage": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Simple insertion</strong><br />The bullet shape nose of the cages helps to insert the cages in a smooth and simple way."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Simple rotation</strong><br />Our circular rail technology helps to guide the Cedar cage into its final position."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Proven material</strong><br />PEEK is a proven material with great biocompatibility and an elastic module close to bone, supporting the natural fusion process."])}
  },
  "calendar": {
    "january": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["January"])},
    "february": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["February"])},
    "march": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["March"])},
    "april": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "may": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["May"])},
    "june": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["June"])},
    "july": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["July"])},
    "august": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "september": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "october": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["October"])},
    "november": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "december": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "career": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Joining Safe Orthopaedics</strong> is above all being integrated into a <strong>passionate team</strong>, <strong>driven by innovation</strong>, and eager to provide the safest treatment to vertebral fracture pathologies, regardless of where or when."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actors and ambassadors of this mission, Safe Orthopaedics employees follow a <strong>path of integration</strong>, allowing them to understand their place and the importance that each one occupies within the company, and with its customers and partners."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joining Safe Orthopaedics is to join <strong>a company of a comfortable size</strong>, well established on the local territory, and with multiple European focal points, with French, English and German collaborators."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>It’s our people who say it best!</strong> Discover them!"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do not hesitate to send us your spontaneous application and join the Safe Orthopaedics experience!"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job offer*"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message*"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The personal data collected on this page are processed by Safe Orthopaedics to create an application form under your name and answer to your application and organize recruitment interviews. You have a right to access your personal data and to limit data collection, you can also request that your personal data be rectified or deleted. FOR MORE INFORMATION:"])},
      "rgpdLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
      "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), " caracters maximum allowed."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email format."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message. It has been sent."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured, please verify all the fieds."])}
    }
  },
  "cc": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine<sup>TM</sup> CC is our ready to use instrumentation<br />for our cervical cage: Walnut"])}
  },
  "cement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cement Kit"])},
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All in one mixing system"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not toxic"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High viscosity"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No waiting time"])},
    "bullet5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No risk of irritation of respiratory<br />apparatus and eyes"])},
    "bullet6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luer lock connection"])}
  },
  "contact": {
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name*"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email*"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject*"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message*"])},
      "rgpd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The personal data collected on this page are processed by Safe Orthopaedics to create an application form under your name and answer to your application and organize recruitment interviews. You have a right to access your personal data and to limit data collection, you can also request that your personal data be rectified or deleted. FOR MORE INFORMATION:"])},
      "rgpdLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click here"])},
      "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required."])},
      "max": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("max")), " caracters maximum allowed."])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email format."])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for your message. It has been sent."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occured, please verify all the fieds."])}
    }
  },
  "connector": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A single kit for a complete solution"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top-loading insertion"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tightening from the top of cross-connector"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low-profile plate: 2 mm"])},
    "bullet5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple and fast handling"])}
  },
  "cypress": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Total surgical freedom</strong><br/>Our Cypress implants are cannulated, fenestrated and pre-assembled on the screw extenders as well as the screwdriver. This enables total surgical freedom in regards to pathologies, approach & screw augmentation with cement, always using the same instrumentation."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>XXL persuasion</strong><br />Our system offers up to 38mm of persuasion."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Unmatched visibility</strong><br />Our image noise cancelling technology allows for an unmatched visibility of the screwhead, the rod & the set screw."])}
  },
  "education": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAFE ACADEMY"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe Academy is designed to provide the unique opportunity for physicians to practice the treatment of Spinal Fracture pathologies utilising cadaveric specimen and didactic information."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The program’s aim is to train neurosurgeons and orthopaedic surgeons on all aspects of a spinal fracture surgery."])},
    "calendar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consult the calendar"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEER to PEER"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe Orthopaedics is committed to providing the best possible training program by offering customised surgeon visits in partnership with its European Education Centers."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Peer to Peer Program has been developed to connect neurosurgeons and orthopaedic surgeons who are interested in learning more about our technologies in a one-to-one environment by sharing best practices during discussion and OR attendance."])},
    "title3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMPANY VISIT"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe Orthopaedics organises guided tours of the company, explanation of manufacturing processes from raw material to sterile product, as well as hands-on training for Operating Room Teams."])}
  },
  "home": {
    "banner_txt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit <a href='https://www.safegrp.com'>www.safegrp.com</a> for share information and financial press releases"])},
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamlined"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR workflow optimisation<br />Global time saving<br />Cost saving"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DETAILS"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No cross-contamination<br />Less risk of infection<br />Perfect visibility (Radiolucent instruments)"])},
    "numberTitle1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cost saving potential*"])},
    "numberText1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using our products can help saving up to 50% of costs per case in cement augmented screw surgeries."])},
    "numberTitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reduction of time*"])},
    "numberText2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using our products can reduce the logistics  and handling time pre- and post-operative by 80% compared to reusable systems."])},
    "numberTitle3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surgical kits sold*"])},
    "numberText3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 20 000 surgical kits have been helping surgeons and patients around the world."])},
    "data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data on file"])}
  },
  "kypho": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyphoplasty System"])},
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Smooth height restauration</strong><br />Our high quality 700 PSI balloon allows for an effortless height restauration, even in hard bone."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Uniform bone consolidation</strong><br />Our balloon allows for a uniform bone consolidation within the vertebral body by creating a high density cavity, which can help to increase the stability of the vertebral body and limit the cement leakage in combination with our high viscosity bone cement."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Compact efficiency</strong><br />The VA system offers you everything you need in one simple box."])}
  },
  "lc": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine<sup>TM</sup> LC is our ready to use instrumentation<br />for our lumbar cage portfolio: Cedar & Elm"])}
  },
  "legal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LEGAL NOTICE"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website <a href='https://www.safeorthopaedics.com'>www.safeorthopaedics.com</a> is published by SAFE ORTHOPAEDICS, a company incorporated under French Law in the form of a société anonyme, with capital of 487.837,93€, registered on the commercial register of Pontoise under number 52 722 646 00020 and whose registered office is located Allée Rosa Luxemburg, Parc des Bellevues, Bâtiment le Californie, 95610 ERAGNY SUR OISE, FRANCE, Intracommunity VAT n° FR 22 520 722 646."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tel.: +33 (0)4 81 09 87 01<br />Fax.: +33 (0)1 34 21 12 00"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered at the Agence Nationale de Sécurité du Médicament et des Produits de santé, located 143-147 Boulevard Anatole France, 93285 SAINT-DENIS CEDEX, France, as a medical device manufacturer,pursuant to article L5211-3-1 of the French Public Health Code."])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Director of publication:</strong> Mr. Pierre Dumouchel, Chief Executive Officer"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Co-Director of publication:</strong> Mr. Sven Claes, Marketing Director"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website is hosted by OVH, a company incorporated under French Law in the form of a société par actions simplifiée, registered on the commercial register of Lille Métropole under number 424 761 419 00045 and whose registered office is located 2 rue Kellermann, 59100 ROUBAIX, FRANCE."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The website is administered by SYNTHES’3D, a company incorporated under French Law in the form of a société par actions simplifiée, registered on the commercial office of Aix-en-Provence under number 495 075 822 00041 and whose registered office is locate 1 rue Mahatma Gandhi Le Decisium, Bâtiment C1, 12090 AIX-EN-PROVENCE, FRANCE."])}
  },
  "menu": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Safe"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Education"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "ps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine PS"])},
    "va": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine VA"])},
    "lc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine LC"])},
    "cc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine CC"])},
    "newsEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News & Events"])},
    "videos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Videos"])},
    "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press Reviews"])},
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal notice"])},
    "dataPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data privacy"])}
  },
  "mission": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our mission <span>safe</span>"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='focus'>Safe Orthopaedics</span> revolutionizing the services offered to surgeons and hospitals treating patients with back pain. Our ready-to-use medical devices (sterile implants and single-use instruments) are associated with virtual surgery assistance technology to simplify the operating flow, reduce operating costs, reduce surgical risks while saving our planet!"])},
    "one": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unprecedented ease of use<br />80% of surgical logistics avoided"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Our technologies are designed to be easy to use:</strong> intuitive designs, optimized number of instruments, pre-assembly of implants on instruments, instructions for use present in each kit or accessible via SORA."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compared to traditional technologies that require multiple checks, cleaning and sterilization between each surgery, our kits are new for each patient, delivered sterile within 24 hours to the operating room whatever the conditions of patient care: emergency, outpatient or conventional . According to a study carried out by the public hospitals of Paris*, 80% of surgical logistics are avoided thanks to our technology."])}
    },
    "two": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50% potential savings on the cost of implants"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The logistics simplified by our technologies reduce the cost of implants by 18%. According to a second study carried out on osteoporotic or tumor pathologies, this saving is increased to 50%."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By digitizing our technology such as the development of new SORA functionalities, we provide concrete solutions to the historical complexity of orthopedic surgeries and to current hospital issues. The management of inventories and the restocking of materials, the cost of sterilization significantly impacted by the increase in the price of energy, the growing requirements for traceability..."])}
    },
    "three": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["66% less risk of infection"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By only offering sterile implants and single-use instruments, we eliminate all risks of cross-contamination and help reduce the risk of infection during surgery by up to 66%. According to our estimates, a traditional implant, delivered non-sterile to the hospital, undergoes an average of 80 cycles of autoclave sterilization before being implanted. Did you know ? What is the impact on the final performance of the implant? Can we guarantee its integrity, cleanliness and biocompatibility? We offer a simple and obvious answer: implants delivered sterile & single-use instruments!"])}
    },
    "four": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less water, Less energy, Less CO2"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concerned with ecological issues, Safe Group works on the ecological footprint of its technologies throughout the life cycle.<br />Eco-design: the design of our instruments is designed to consume less material while remaining simple and intuitive to use in the operating room. One of our multifunction instruments made from reinforced polymer weighing only a few grams replaces several traditional instruments that can weigh up to half a kilo."])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eco-production: since the acquisition of our subcontractor in 2020, we have centralized production from machining to sterile packaging. Compared to multi-supplier outsourcing, we have reduced water and energy consumption related to numerous cleanings but also the emission of C02 related to transport. "])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eco-distribution: in close collaboration with our customers, we optimize the necessary equipment and the transport associated with each surgery: the 40 kg of traditional technologies are replaced by ready-to-use kits of 2 kg and round trips of traditional equipment to small hospitals are cancelled. Due to the simplicity of our technologies, we are able in 2023 to supply operating theaters around the world within 48 hours."])}
    }
  },
  "modalPro": {
    "isPro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you a healthcare professional?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
  },
  "news": {
    "noPosts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no posts."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "pressReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press review"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
    "career": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Career"])},
    "docs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentations"])},
    "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial presentations"])},
    "pressRelease": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press release"])},
    "dl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download:"])},
    "shareholdersMeeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shareholders Meeting"])}
  },
  "oak": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Uncompromising fracture reduction</strong><br />The OAK screw is the only MIS fracture reduction system that combines the advantages of both a monoaxial and a polyaxial screw through a built in auto-reduction function. It eliminates having to compromise between open surgery with monoaxial screws and MIS surgery with polyaxial screws."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Unmatched visibility</strong><br />Our image noise cancelling technology allows for an unmatched visibility of the screwhead, the rod & the set screw."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Unparalleled strength</strong><br />The Oak screw is at least twice as strong as a standard polyaxial pedicle screw which can help to prevent revision surgeries because of screw failures."])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA PRIVACY"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All personal data communicated to Safe Orthopaedics via the website are exclusively processed by <strong>Safe Orthopaedics</strong> as the data controller under the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collection of personal data is strictly limited (data minimization principle) and only for audience measurement, management of application on job offers (“Career” tab”) and contact requests (“Contact” tab”)."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The collection of data have the following purposes:<ul><li>Measure the audience on the website, or</li><li>Create an application form under your name in order to answer to your application and organize recruitment interviews, or</li><li>Allow Safe Orthopaedics to contact you later in order to answer your requests.</li></ul>"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All personal data are exclusively processed by <strong>Safe Orthopaedics</strong> and only by the members of its staff having a need to know such information. The personal data will be kept for no longer than two years after their collection."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have a right to access your personal data and to limit data collection, you can also request that your personal data be rectified or deleted, under the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 and French Law n°78-17 of 6 January 1978 as amended."])},
    "text6": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["In case of any such request or complaint, please send a notification to Safe Orthopaedics, Allée Rosa Luxemburg, Parc des Bellevues, Bâtiment le Californie, 95610 ERAGNY SUR OISE, FRANCE or ", _interpolate(_named("mail")), " ."])}
  },
  "product": {
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discover"])}
  },
  "productRange": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Range"])}
  },
  "ps": {
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The original all-round kit for the treatment<br />of spinal fractures and degenerative pathologies"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The essential kit for the percutaneous treatment<br />of spinal fractures"])}
  },
  "slides": {
    "slide1": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leader of READY-TO-USE solutions"])},
      "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for spinal diseases treatement"])}
    },
    "slide2": {
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming<br /> soon"])}
    },
    "slide3": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our thinnest towers ever!"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XXL persuasion"])},
      "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unmatched visibility"])},
      "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StreamLined surgery"])}
    },
    "slide4": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to use<br /> technology"])},
      "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR workflow optimisation<br />Time & cost saving<br />Reduced risk of infection"])}
    },
    "slide5": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spinal fracture treatment<br />solutions"])}
    },
    "slide6": {
      "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Degenerative treatment<br />solutions"])}
    }
  },
  "scroll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scroll to continue"])},
  "sycamore": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Kyphoplasty Pedicular Anchorage</strong><br />Targeted vertebral height restoration<br />Strong pedicular anchorage<br />Efficient, fast integrated balloon stop"])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>120+ surgeries 2022</strong>"])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>12 month patient follow-up</strong>"])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Multi-centre follow-up ongoing in France & Germany</strong>"])}
  },
  "teams": {
    "papillon": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicolas has 18 years of experience in the orthopedic and spinal implants market. Following a 3 years experience has export manager, he found in 2005 Orthopaedic & Spine Development (OSD), based in Avignon (France). President and main shareholder of the company, OSD has developped a product protofolio of spinal implants, sold in 15 countries. Nicolas is graduated from a Business school, 2003 – SKEMA, Sophia Antipolis, France."])}
    },
    "reynaud": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Financial Officer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["François-Henri has more than 15 years of experience in corporate finance. He first contributed to the financing of innovative business projects in financial institutions, then piloted several top-end financing operations (fundraising, mergers and acquisitions) as Chief Financial Officer of listed and unlisted companies in sectors such as medical devices or renewable energies."])}
    },
    "droulout": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Technical Officer and Co-Founder"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Born in 1976, Thomas has developed a strong and large technical and market expertise, acquired through a 15 years’ experience in the Spine Industry. Before founding Safe orthopaedics in 2010, Thomas had been involved in numerous Spine products projects as Senior Project Manager. Thomas holds a Master in Engineering of Advanced Materials and Management from French Arts et Metiers and ISTM."])}
    },
    "beyer": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chief Commercial Officer"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikolaus Beyer has over 20 years of experience in the spine market and has held a number of senior management positions. Prior to joining Safe Orthopaedics, Nik served as General Manager at K2M for more than 7 years. He started his career with J&J in a sales role. Nik joined Stryker in 1997 where he first held the position of Sales & Marketing Manager at Stryker and served, during more than 7 years, as General Manager/Managing Director GSA/Benelux and Nordics (11 countries) for spine, craniomaxillofacial, biotech and navigation (surgical solutions)."])}
    },
    "vazquez": {
      "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources Director"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nathalie has been working for 30 years in international contexts. Before joining Safe Orthopaedics, she has been working for over 12 years at SpineVision where she started as Office Manager. She was quickly promoted to the position of Legal & General Administration Manager, notably responsible of the overall management of human resources for more than 11 years. Prior to this, Nathalie held several positions as Executive Assistant of top Executives of major international groups."])}
    }
  },
  "va": {
    "title1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SteriSpine<sup>TM</sup> VA is the product platform for our<br />ready to use Kyphoplasty and Cement systems."])}
  },
  "walnut": {
    "bullet1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Zero profile stability</strong><br />The Walnut implant includes two screws that are entirely integrated into the cage and anchor it into the vertebral body."])},
    "bullet2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Enhanced safety</strong><br />The two screws are safely guided into the vertebral body, assuring an optimal angulation and anchorage of the cage."])},
    "bullet3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Proven material</strong><br />PEEK is a proven material with great biocompatibility and a modulus of elasticity close to bone, supporting the natural fusion process."])},
    "bullet4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<strong>Β-TCP</strong><br />A made-to-measure bone graft substitute for an optimal fusion."])}
  }
}