import { createStore } from 'vuex'

export default createStore({
  state: {
    menu_open : false,
    // apiUrl : "http://localhost:8080/api",
    // assetsUrl : "http://localhost:8080/",
    apiUrl : "https://api.safegrp.com/api",
    assetsUrl : "https://api.safegrp.com/",
  },
  mutations: {
    closeMenu(state) {
      state.menu_open = false;
    }
  },
  actions: {
  },
  modules: {
  }
})
